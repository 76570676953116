<template>
  <div>
    <div class="card rounded shadow-sm">
      <div class="card-body">
        <form
          @submit.prevent="
            getCavs(DEFAULT_URL, {
              searchLoader: { enabled: true },
              tableLoader: { enabled: false },
            })
          "
        >
          <div class="form-row mb-3">
            <div class="col-auto">
              <input
                v-model="params.app_id"
                type="search"
                placeholder="CAV App No"
                class="form-control"
                maxlength="8"
              />
            </div>

            <div class="col-auto">
              <input
                v-model="params.secpa_no"
                type="search"
                class="form-control"
                placeholder="SECPA No"
              />
            </div>

            <div class="col-auto">
              <input
                v-model="params.last_name"
                type="search"
                class="form-control"
                placeholder="Last Name"
              />
            </div>

            <div class="col-2">
              <select
                name=""
                class="form-control"
                id=""
                v-model="params.hei_code"
              >
                <option value="" selected disabled hidden>HEI</option>
                <option value="">All</option>
                <option
                  v-for="hei in $store.state.heis"
                  :key="hei.code"
                  :value="hei.code"
                >
                  {{ hei.name }}
                </option>
              </select>
            </div>

            <div class="col-2">
              <select
                name=""
                v-model="params.program_name"
                class="form-control"
                id=""
              >
                <option value="" selected disabled hidden>Program</option>
                <option value="">All</option>
                <option
                  v-for="program in $store.state.programs"
                  :key="program.id"
                  :value="program.program_name"
                >
                  {{ program.program_name }}
                </option>
              </select>
            </div>
            <div class="col-auto">
              <select v-model="params.type" name="" class="form-control" id="">
                <option value="" selected disabled hidden>Type</option>
                <option value="">All</option>
                <option value="GR">GR </option>
                <option value="UE">UE </option>
              </select>
            </div>
            <div class="col-auto">
              <select
                name=""
                v-model="params.purpose"
                class="form-control"
                id=""
              >
                <option value="" selected disabled hidden>Purpose</option>
                <option value="">All</option>
                <option value="Local">Local</option>
                <option value="Abroad">Abroad</option>
              </select>
            </div>
          </div>

          <div class="form-row">
            <div class="col-auto">
              <div class="form-inline">
                Issued from
                <input
                  type="date"
                  class="form-control mx-2"
                  v-model="params.issued_from"
                />
                to
                <input
                  type="date"
                  class="form-control ml-2"
                  v-model="params.issued_to"
                />
              </div>
            </div>

            <div class="col-auto">
              <button type="submit" id="cav-search-btn" class="btn btn-primary">
                <span v-if="!searchLoader">Go</span>
                <div v-else class="spinner-border spinner-border-sm"></div>
              </button>
              <button class="btn btn-link" @click.prevent="params.reset()">
                Reset
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="card rounded shadow-sm">
      <div class="card-body">
        <div class="d-flex justify-content-between mb-3">
          <div class="form-inline">
            <div class="form-group">
              <label for="">No. of rows</label>
              <select
                name=""
                id=""
                class="form-control ml-2 mr-3"
                v-model="params.per_page"
                @change="
                  getCavs(DEFAULT_URL, {
                    searchLoader: { enabled: false },
                    tableLoader: { enabled: false },
                  })
                "
              >
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
              </select>
            </div>

            <button
              class="btn btn-outline-secondary text-dark"
              @click.prevent="exportToCSV"
            >
              <i class="bi bi-filetype-csv mr-2"></i>Export to CSV
            </button>
          </div>
          <div class="d-flex justify-content-right">
            <div class="my-auto mr-2">
              <span
                >{{
                  `Showing
              ${pagination.from || 0}-${pagination.to || 0}
                of ${pagination.total || 0} results`
                }}
              </span>
            </div>
            <nav>
              <ul class="pagination mb-0">
                <li
                  class="page-item"
                  :class="{ disabled: !pagination.prev_page_url }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="
                      getCavs(pagination.prev_page_url, {
                        searchLoader: { enabled: false },
                        tableLoader: { enabled: false },
                      })
                    "
                  >
                    <span aria-hidden="true"
                      ><i class="bi bi-chevron-left"></i
                    ></span>
                    <span class="sr-only">Previous</span>
                  </a>
                </li>

                <li
                  class="page-item"
                  :class="{ disabled: !pagination.next_page_url }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="
                      getCavs(pagination.next_page_url, {
                        searchLoader: { enabled: false },
                        tableLoader: { enabled: false },
                      })
                    "
                  >
                    <span aria-hidden="true"
                      ><i class="bi bi-chevron-right"></i
                    ></span>
                    <span class="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <table
          id="cav-issuances-table"
          class="table table-sm table-bordered table-hover"
        >
          <thead class="">
            <th>APP NO</th>
            <th>SECPA NO</th>
            <th>CAV NO</th>
            <th>SO NO</th>
            <th>NAME</th>
            <th>HEI</th>
            <th>PROGRAM</th>
            <th>TYPE</th>

            <th>PURPOSE</th>
            <th>PROCESSED BY</th>
            <th>ACTION</th>
          </thead>
          <tbody class="text-uppercase">
            <tr v-show="tableLoader">
              <td colspan="18" class="text-center">
                <div class="spinner-border spinner-border-sm mr-2"></div>
                Loading...
              </td>
            </tr>
            <tr v-if="!cavs.length && !tableLoader" class="text-center">
              <td colspan="18">No results found.</td>
            </tr>
            <tr
              v-for="cav in cavs"
              :key="cav.id"
              @click.stop="openModal(cav)"
              :class="
                cav.status_id == 5 || cav.status_id == 6
                  ? 'table-secondary'
                  : ''
              "
            >
              <th>
                <i
                  v-if="cav.status_id == 5 || cav.status_id == 6"
                  class="bi bi-lock-fill mr-1"
                ></i>
                {{ cav.app_id }}
              </th>

              <td>
                <a href="#" v-if="cav.path" @click.prevent="openUrl(cav.path)"
                  >{{ cav.secpa_no }}
                </a>
                <span v-else>{{ cav.secpa_no }}</span>
              </td>
              <td>
                <a href="#" @click.prevent="print(cav)"
                  >CAV-{{ cav.control_no }}</a
                >
              </td>
              <td>
                <span v-if="cav.soNumber !== null" class="form-text">
                  <a
                    href="#"
                    v-if="cav.so_path"
                    @click.prevent="openUrl(cav.so_path)"
                  >
                    {{ cav.soLevel }} {{ cav.soRegion }} {{ cav.soNumber }}
                    {{ cav.soSequence }} {{ cav.soSeries }}</a
                  >
                  <span v-else>
                    {{ cav.soLevel }} {{ cav.soRegion }} {{ cav.soNumber }}
                    {{ cav.soSequence }} {{ cav.soSeries }}</span
                  ></span
                >
              </td>
              <td class="text-left">
                {{ cav.firstname }} {{ cav.middlename }} {{ cav.lastname }}
              </td>
              <td>{{ cav.abbrev }}</td>
              <td class="text-left" width="30%">{{ cav.progName }}</td>
              <td>{{ cav.student_type }}</td>

              <td>{{ cav.purpose }}</td>
              <td>{{ cav.processed_by.split(' ')[1] }}</td>

              <td>
                <div class="btn-group">
                  <button
                    class="btn btn-sm btn-outline-secondary"
                    @click.stop="openUploadCavModal(cav)"
                  >
                    <i class="bi bi-cloud-arrow-up text-dark"></i>
                  </button>

                  <button
                    class="btn btn-sm btn-outline-secondary"
                    @click.stop="deleteCav(cav.id, cav.lastname)"
                  >
                    <i class="bi bi-trash text-danger"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal -->
    <CavModal
      ref="CavModal"
      @getCavs="
        getCavs(DEFAULT_URL, {
          searchLoader: { enabled: false },
          tableLoader: { enabled: false },
        })
      "
    ></CavModal>
    <PrintCAV v-show="false" ref="PrintCAV"></PrintCAV>

    <CavUploadModal
      ref="CavUploadModal"
      v-on:getCavs="
        getCavs(DEFAULT_URL, {
          searchLoader: { enabled: false },
          tableLoader: { enabled: false },
        })
      "
    ></CavUploadModal>
  </div>
</template>

<script>
import CavModal from './components/CavModal.vue';
import PrintCAV from '../CavApplications/components/PrintCAV.vue';
import CavUploadModal from './components/CavUploadModal.vue';
import Papa from 'papaparse';

export default {
  name: 'Cavs',
  components: {
    CavModal,
    PrintCAV,
    CavUploadModal,
  },
  data() {
    return {
      params: new Form({
        app_id: '',
        secpa_no: '',
        hei_code: '',
        program_name: '',
        type: '',
        purpose: '',
        last_name: '',
        issued_from: '',
        issued_to: '',
        per_page: 10,
      }),
      searchLoader: false,
      searchValue: '',
      cavs: [],
      tableLoader: false,
      prefix: 'https://drive.google.com/file/d/',
      pagination: {},
      DEFAULT_URL: 'api/cavs',
    };
  },
  methods: {
    exportToCSV() {
      const csv = Papa.unparse(this.cavs);
      this.downloadCSV(csv, 'cav_issuances_data.csv');
    },
    downloadCSV(csv, filename) {
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');

      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        // Other browsers
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    getCavs(page_url, options) {
      this.searchLoader = options.searchLoader.enabled;
      this.tableLoader = options.tableLoader.enabled;

      page_url = page_url || this.DEFAULT_URL;

      axios
        .get(page_url, {
          params: this.params,
        })
        .then((res) => {
          this.cavs = res.data.data;
          this.searchLoader = this.tableLoader = false;
          this.makePagination(res.data);
        })
        .catch((err) => {
          this.searchLoader = this.tableLoader = false;
        });
    },

    deleteCav(id, lastname) {
      if (
        confirm(
          'Are you sure you want to delete the selected item? \nID: ' +
            id +
            '\nName: ' +
            lastname
        )
      ) {
        axios
          .delete('api/cavs/' + id)
          .then((res) => {
            toastr.success(res.data.msg, 'Information');
            this.getCavs(this.DEFAULT_URL, {
              searchLoader: { enabled: false },
              tableLoader: { enabled: false },
            });
          })
          .catch((err) => {
            toastr.error('Something went wrong.', 'Error');
          });
      }
    },

    split(string) {
      let processedBy = string.split(' ');
      return processedBy[0];
    },

    openModal(cav) {
      if (cav.status_id == 5 || cav.status_id == 6) {
        toastr.error('The selected row is already locked.', 'Error');
        return;
      }
      this.$refs.CavModal.openModal(cav);
    },

    print(cav) {
      this.$refs.PrintCAV.print(cav);
    },

    openUploadCavModal(id) {
      this.$refs.CavUploadModal.openModal(id);
    },

    openUrl(path) {
      const url = this.prefix + path + '/view';
      window.open(url, '_blank');
    },
    getHeis() {
      if (this.$store.state.heis.length) {
        return;
      }
      axios
        .get('api/heis')
        .then((response) => {
          this.$store.state.heis = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPrograms() {
      if (this.$store.state.isProgramsMounted) {
        return;
        // execute code when the component is already mounted
      }

      axios
        .get('api/programs')
        .then((response) => {
          this.$store.state.programs = response.data;
          this.$store.state.isProgramsMounted = true;
        })
        .catch((error) => {});
    },
    makePagination(data) {
      let pagination = {
        current_page: data.current_page,
        last_page: data.last_page,
        next_page_url: data.next_page_url,
        prev_page_url: data.prev_page_url,
        from: data.from,
        to: data.to,
        total: data.total,
      };

      this.pagination = pagination;
    },
  },
  computed: {},
  mounted() {
    this.getHeis();
    this.getPrograms();
    this.getCavs(this.DEFAULT_URL, {
      searchLoader: { enabled: false },
      tableLoader: { enabled: true },
    });
  },
};
</script>

<style scoped>
#cav-issuances-table tr {
  cursor: pointer;
}
</style>
